import React from 'react';
import { useHistory, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { get } from 'lodash';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import { OktaSecurityHOC, verifySessionAndMaintainRedirectOnExternalApp, handleLogoutRedirect } from "../../../helpers/OktaUtility";
import { cacheManager, getUserInfo, isOKTAStorageAvailableWithRequiredkey, datadogLogger, DATADOG_LOG_TYPE } from 'helpers/utilCommon';
import { APP_ROOT_CONTENT_STYLE, IDENTITY_DELEGATION_FLAG, USER_INFO } from 'helpers/constant';
import { getCookie, setCookie } from '../../../helpers/manageCookies';

import { LOGIN_USER_MODEL, cookieKeysEnum } from '../../../models/common.models';
import { ComponentUrls, StaticPages } from 'models/navigation.models';
import { isPublicRoutes, PrivateRoutes, PublicRoute } from '../../../hoc/RouteHOC';
import { setAppLoader } from '../../../redux/app/actions.js';
import asyncComponent from '../../../asyncComponent';
import { withFlagFeature } from 'providers/flagFeature';
import SafariAutofillMessage from 'components/atoms/SafariAutofillMessage';
import CustomLoader from '../../atoms/CustomLoader';
import ScrollToTop from '../../atoms/ScrollToTop';
import ToastMessage from '../../atoms/ToastMessage';
import FooterComp from '../../organisms/Footer';
import HeaderComp from '../../organisms/Header';
import AventriRegistration from '../aventri';
import MyChildAppComponents from '../myChildAppComponents';
import MyRosterComponents from './RosterChildApp';
import AlertBanner from '../../organisms/AlertBanner';
import IdentityDelegationBanner from '../../organisms/IdentityDelegationBanner';

const MyAccountRoutes = asyncComponent(() => {
  return import('../../../chunks/myAccount.chunk');
});

const DownloadDocumentsRoutes = asyncComponent(() => {
  return import('../../../chunks/downloadDocuments.chunk');
});

const ApplicationsRoutes = asyncComponent(() => {
  return import('../../../chunks/applications.chunk');
});

const StaticPagesRoutes = asyncComponent(() => {
  return import('../../../chunks/staticPages.chunk');
});

const App = (props) => {
    const { isSignOutTrigger, isAppLoading, isMiniAppLoading, showToastMessage, content, uuidNumber, isReactTesting } = props;
    const { authState } = useOktaAuth();
    const userInfo = useSelector(store => store.user.userInfo);
    const [isOktaInitializing, setIsOktaInitializing] = React.useState(true);
    const [isRestrictedUrl, setIsRestrictedUrl] = React.useState(true);
    const [isRedirectUrl, setIsRedirectUrl] = React.useState(false);
    const [isMemberStillLoginInAnotherTab, setIsMemberStillLoginInAnotherTab] = React.useState('');

    React.useEffect(() => {
        window.addEventListener("storage", (e) => {
            if (e.key === 'isMemberStillLoginInAnotherTab') {
                setIsMemberStillLoginInAnotherTab(e.newValue);
            }
        });
    }, [])

    React.useEffect(() => {
        if (authState) {
            if (authState.isAuthenticated) {
                setIsOktaInitializing(true);
                handleMemberLogin();
            } else {
                setIsOktaInitializing(false);
            }
        }
    }, [authState]);

    React.useEffect(() => {
        if (userInfo) {
            datadogLogs.setUser({
                MAEUserID: userInfo.MAEUserID,
                memberAccountNumber: userInfo.memberAccountNumber
            });
        } else {
            datadogLogs.clearUser();
        }
    }, [userInfo]);

    const handleMemberLogin = () => {
        if (authState && authState.isAuthenticated && authState.idToken?.claims) {
            setCookie(cookieKeysEnum["IS-OKTA-VERIFIED"], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
            let data = {
                userInfo: authState.idToken.claims,
                request_mode: LOGIN_USER_MODEL.MEMBER_LOGIN,
            };
            props.handleMemberLogin(data, result => {
                setIsOktaInitializing(false);
            });
        }
    }

    return (
        <>
            {isMiniAppLoading ? <CustomLoader testId='mini-spinner' /> : null}
            {isAppLoading || isRedirectUrl || isSignOutTrigger || isOktaInitializing ? <CustomLoader testId='app-spinner' /> : null}
            {showToastMessage ? (<div className="topAlert"><ToastMessage content={content} uuidNumber={uuidNumber} /></div>) : null}
            <ScrollToTop />
            <SafariAutofillMessage />
            <AlertBanner />
            {!isReactTesting && <HeaderComp />}
            <IdentityDelegationBanner />
            <section style={APP_ROOT_CONTENT_STYLE}>
                {!(isSignOutTrigger || isRedirectUrl || isOktaInitializing) &&
                    <Switch>
                        {/* <PublicRoute path='/' exact component={null} /> */}
                        <PublicRoute path='/application' component={ApplicationsRoutes} />
                        <PublicRoute path='/Msgs' component={StaticPagesRoutes} />
                        <PublicRoute path='/aventri-registration' component={AventriRegistration} />
                        <PublicRoute path='/download' component={DownloadDocumentsRoutes} />
                        <PrivateRoutes path="/MyASTM" component={MyAccountRoutes} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/ballot" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/wi" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/standard" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/meeting" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/RMLogin" component={MyRosterComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <Redirect from='/login' to="/MyASTM" />
                        <Redirect excat from='/' to="/MyASTM" />
                        <Route path='*' component={(props) => {
                            const { pathname } = props.location;
                            if (pathname !== '/auth') {
                                window.open(process.env.REACT_APP_PUB_404_URL, "_self");
                            }
                            return false;
                        }} />
                    </Switch>
                }
            </section>
            <FooterComp />
        </>
    );
}

// const OktaAuthApp = withOktaAuth(App);

const RouterApp = (props) => {
    return (
        <OktaSecurityHOC>
            <App {...props} />
        </OktaSecurityHOC>
    );
}

export default withFlagFeature(withRouter(RouterApp));